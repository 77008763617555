<template>
  <div class="cookie-settings">
    <img src="@/assets/icons/close.svg" alt="Close" @click="closeComponent" />
    <simplebar data-simplebar-auto-hide="true" data-simplebar class="content__wrapper">
      <div class="content">
        <h2 class="h6">Was sind Cookies?</h2>
        <p>
          Ein Cookie ist eine kleine Textdatei, die auf deinem Gerät gespeichert wird. Cookies dienen dazu, eine fehlerfreie und sichere Nutzung einer Website
          zu gewährleisten. Sie können auch dazu dienen, das Nutzerverhalten zu analysieren, um die Website forlaufend zu verbessern und nutzerfreundlicher zu
          gestalten.
        </p>
        <h2 class="h6">Hier kannst du die Cookies auswählen, die auf deinem Gerät gespeichert werden sollen</h2>
        <div class="input__wrapper">
          <input id="essential-cookies" type="checkbox" checked disabled />
          <label for="essential-cookies">essenzielle Cookies (nicht optional)</label>
        </div>
        <p>
          Diese Cookies sind notwendig für eine fehlerfreie und sichere Nutzung der App. Sie ermöglichen neben einem sicheren Log-In Prozess, auch den Schutz
          deiner Nutzerdaten, indem die App vor kriminellen und betrügerischen Hacking Attacken oder anderer verdächtiger Aktivitäten geschützt werden kann.
        </p>
        <div class="input__wrapper">
          <input id="tracking-cookies" type="checkbox" :checked="acceptGACookies" @click="toggleTrackingCookies" />
          <label for="tracking-cookies">Analyse- und Tracking Cookies</label>
        </div>
        <p>
          Diese Cookies werden für Analysezwecke verwendet. Dabei erfährt teamee mehr über das Nutzerverhalten auf der Website und kann somit das
          Nutzererlebnis, sowie den Service von teamee stetig verbessern und weiterentwickeln.
        </p>
        <div class="button__wrapper">
          <button class="button__transparent" @click="enableTracking">alle Cookies erlauben</button>
          <button class="cta button__cta" @click="saveCookieSettings">Auswahl speichern</button>
        </div>
      </div>
    </simplebar>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "CookieSettings",
  components: {
    simplebar,
  },
  methods: {
    closeComponent() {
      this.$emit("closeCookieSettings", false);
    },
    setCookies() {
      let d = new Date();
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 365); // expires in 1 year
      document.cookie = "acceptGACookies" + "=" + "true" + ";path=/;expires=" + d.toGMTString();

      this.$store.commit("SET_GA_COOKIES", true);
    },
    enableTracking() {
      this.setCookies();
      this.$ga.enable(); // enable Google Analytics
    },
    toggleTrackingCookies() {
      if (this.acceptGACookies) {
        this.$store.commit("SET_GA_COOKIES", false);
      } else {
        this.$store.commit("SET_GA_COOKIES", true);
      }
    },
    saveCookieSettings() {
      // if User enabled Tracking:
      if (this.acceptGACookies) {
        this.enableTracking(); // enable Google Analytics (Opt-In)
        this.$store.commit("SET_GA_COOKIES", true);
      } else {
        this.$ga.disable(); // disable Google Analytics (Opt-Out)
        this.$store.commit("SET_GA_COOKIES", false);
        document.cookie = "acceptGACookies= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"; // delete GA Cookie
      }
    },
  },
  computed: {
    acceptGACookies() {
      return this.$store.getters.GA_COOKIES;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.cookie-settings {
  z-index: 500;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: $dark-navy-blue;
  padding: 24px;
  @media screen and (max-width: 960px) {
    width: 100%;
    transform: translateX(-24px);
    padding: 12px 12px 12px 24px;
  }
  img {
    z-index: 600;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 32px;
    cursor: pointer;
  }
  .content__wrapper {
    height: 100%;
    .content {
      padding-right: 28px;
      h2 {
        line-height: 1.5;
        margin-bottom: 8px;
      }
      p {
        margin-bottom: 12px;
        color: #afafaf;
        line-height: 1.5;
      }
      .input__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-bottom: 4px;
        input {
          margin-right: 8px;
        }
      }
      .button__wrapper {
        margin: 24px 0 120px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-wrap: wrap;
        .cta {
          margin: 12px 0 0 12px;
        }
      }
    }
  }
}
</style>
